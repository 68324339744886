import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../../components/shared/page-with-cms-wrapper";

const Capture = ({
                   path,
                   data: {
                       strapiFeaturesCapture: {
                         Title,
                         Content,
                         SeoTitle,
                         MetaDescriptions,
                       },
                   },
               }) => {
    return (
        <PageWithCmsWrapper
            path={path}
            title={Title}
            content={Content}
            seoProps={{ title: SeoTitle, description: MetaDescriptions}}
        />
    );
};


export default Capture;

export const query = graphql`
  query FeaturesCaptureQuery {
    strapiFeaturesCapture {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
